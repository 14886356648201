import Utility from "../../../shared/utility.js";
import EncryptUtility from "./encrypt-utility.js";
import AddFavourite from "./addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      createLPNValid: false,
      numofLPN: "",
      primaryLabelSize: "",
      primaryPrinterType: "",
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
      showPrimary: true,
      max5Rule: [
        (v) => !!v || "Field is required",
        (v) => (v || "").length <= 5 || "Max Length of 5 character",
        (v) => (v && v > 0) || "Number should be greater than 0",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
      isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ( charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
//
    filterInput(event) {
      let inputValue = event.target.value;
      
      // Remove any non-numeric characters
      let numbers = inputValue.replace(/[^0-9]/g, '');
      
      if (numbers.length === 0) {
        this.rawInput = '';
        return;
      }
      let formatted;
      if (numbers.length <= 3) {
        formatted = `${numbers[0]}-${numbers.slice(1)}`;
      } else if (numbers.length <= 6) {
        formatted = `${numbers[0]}-${numbers.slice(1, 4)}-${numbers.slice(4)}`;
      } else {
        formatted = `${numbers[0]}-${numbers.slice(1, 4)}-${numbers.slice(4, 7)}`;
      }

      this.rawInput = formatted;
    },
    formatValidator(value) {
      const regex = /^[0-9]-[0-9]{3}(-[0-9]{3})?$/;
      return regex.test(value) || 'Please use the correct format: X-XXX or X-XXX-XXX';
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.createLPNValid = false;
      this.numofLPN = "";
      this.$refs.CreateLPNForm.resetValidation();
    },
    //Create Label based on number of Labels entered
    createLPN() {
      if (!this.$refs.CreateLPNForm.validate()) return false;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let createLPNLabel = {
        num: parseInt(this.numofLPN),
        UserId: parseInt(this.userId),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post("/ut/lpnlabel_create", createLPNLabel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            PrinterDetails.printLabelDataArray(responsedata.Labels, this.primaryName);
            this.resetFunction();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
